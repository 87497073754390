import template from './welcomeOverlay.html?raw'
import logoUrl from '../assets/logo.svg'

export function initWelcomeOverlay() {
    return {
        template,
        show: true,
        logoUrl,
        currentImageIndex: 0,
        
        startShopping() {
            console.log('Starting shopping, hiding overlay');
            this.show = false;
            // go full screen
            document.documentElement.requestFullscreen();  
        },
        
        init() {
            console.log('Welcome overlay initialized, show:', this.show);
            window.addEventListener('reset-welcome', () => {
                console.log('Reset welcome received');
                this.show = true;
            });

            // Start carousel if we have images
            if (Alpine.store('socket').images.length > 0) {
                this.startImageCarousel();
            } else {
                // Watch for images to be loaded
                Alpine.effect(() => {
                    if (Alpine.store('socket').images.length > 0) {
                        // Start carousel only if we haven't started it yet
                        if (!this.carouselInterval) {
                            this.startImageCarousel();
                        }
                    }
                });
            }
        },

        startImageCarousel() {
            this.carouselInterval = setInterval(() => {
                const images = Alpine.store('socket').images;
                if (images.length > 0) {
                    this.currentImageIndex = Math.floor(Math.random() * images.length);
                }
            }, 5000);
        }
    }
} 