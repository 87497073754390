import template from './cartComponent.html?raw'
import QRCode from 'qrcode'

// Define cart store factory function
const createCartStore = () => ({
    items: [],
    
    addItem(item, quantity) {
        const existingItem = this.items.find(i => i.id === item.id);
        const socketStore = Alpine.store('socket');
        const stockItem = socketStore.items.find(i => i.id === item.id);
        
        if (!stockItem || stockItem.stock <= 0) return;
        
        if (existingItem) {
            const newQuantity = existingItem.quantity + quantity;
            if (newQuantity <= stockItem.stock) {
                existingItem.quantity = newQuantity;
                this.notifyCartUpdate();
            }
        } else {
            if (quantity <= stockItem.stock) {
                this.items.push({
                    id: item.id,
                    name: item.name,
                    price: item.price,
                    quantity: quantity,
                    image: item.images[0]
                });
                this.notifyCartUpdate();
            }
        }
    },
    
    removeItem(itemId) {
        this.items = this.items.filter(item => item.id !== itemId);
        this.notifyCartUpdate();
    },
    
    updateQuantity(itemId, quantity) {
        if (quantity <= 0) {
            this.removeItem(itemId);
            return;
        }
        
        const socketStore = Alpine.store('socket');
        const stockItem = socketStore.items.find(i => i.id === itemId);
        
        if (!stockItem) return;
        
        const item = this.items.find(i => i.id === itemId);
        if (item && quantity <= stockItem.stock) {
            item.quantity = quantity;
            this.notifyCartUpdate();
        }
    },
    
    clearCart() {
        this.items = [];
        this.notifyCartUpdate();
    },
    
    getTotal() {
        return this.items.reduce((total, item) => total + (item.price * item.quantity), 0);
    },
    
    getItemCount() {
        return this.items.reduce((count, item) => count + item.quantity, 0);
    },
    
    notifyCartUpdate() {
        window.dispatchEvent(new CustomEvent('cart-updated', {
            detail: {
                itemCount: this.getItemCount(),
                total: this.getTotal()
            }
        }));
    },
});

export function initCartComponent() {
    if (!Alpine.store('cart')) {
        const store = createCartStore();
        store.items = Alpine.$persist(store.items).as('cart-items');
        Alpine.store('cart', store);
    }

    return {
        isOpen: false,
        showPayment: false,
        selectedPayment: null,
        paymentUrl: null,
        isGeneratingQR: true,
        QRCode,
        template,
        paymentConfirmed: false,
        purchasedItems: [],
        
        paymentMethods: [
            {
                id: 'cash',
                name: 'Cash',
                description: 'Pay with cash upon drop off',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" /></svg>'
            },
            {
                id: 'credit',
                name: 'Digital',
                description: 'Credit Card / Apple Pay / Google Pay / Cash App',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" /></svg>'
            }
        ],

        selectPaymentMethod(methodId) {
            this.selectedPayment = methodId;
            this.isGeneratingQR = true;
            this.paymentUrl = null;
            
            if (methodId === 'credit') {
                const socketStore = Alpine.store('socket');
                const cartStore = Alpine.store('cart');
                socketStore.socket.emit('generatePaymentLink', {
                    items: cartStore.items
                });
            }
        },
        
        init() {
            // Add passive scroll listeners to cart container
            const cartContainer = this.$el.querySelector('.scrollbar-hide');
            if (cartContainer) {
                cartContainer.addEventListener('touchstart', () => {}, { passive: true });
                cartContainer.addEventListener('touchmove', () => {}, { passive: true });
                cartContainer.addEventListener('wheel', () => {}, { passive: true });
            }

            // Add passive touch listeners to payment section
            const paymentSection = this.$el.querySelector('.overflow-y-auto');
            if (paymentSection) {
                paymentSection.addEventListener('touchstart', () => {}, { passive: true });
                paymentSection.addEventListener('touchmove', () => {}, { passive: true });
            }

            this.$watch('isOpen', (value) => {
                document.body.style.overflow = value ? 'hidden' : '';
            });

            // Add socket event listeners
            const socketStore = Alpine.store('socket');
            
            // Remove existing listeners first
            socketStore.socket.off('paymentLinkGenerated');
            socketStore.socket.off('paymentLinkError');
            socketStore.socket.off('paymentConfirmed');
            
            // Add new listeners
            socketStore.socket.on('paymentLinkGenerated', (data) => {
                this.paymentUrl = data.url;
                this.isGeneratingQR = false;
                console.log('Payment link generated:', data);
                console.log('Cart items:', Alpine.store('cart').items);
            });

            socketStore.socket.on('paymentLinkError', (error) => {
                console.error('Payment link error:', error);
                alert('Failed to generate payment link. Please try again.');
            });

            socketStore.socket.on('paymentConfirmed', (data) => {
                console.log('Payment confirmed:', data);
                
                // Get current cart items
                const currentItems = Alpine.store('cart').items;
                console.log('Current cart items:', currentItems);
                
                // Store the purchased items
                this.purchasedItems = JSON.parse(JSON.stringify(currentItems));
                console.log('Stored purchased items:', this.purchasedItems);
                
                // Set confirmation state
                this.paymentConfirmed = true;
                
            });
        },

        destroy() {
            const cartContainer = this.$el.querySelector('.scrollbar-hide');
            if (cartContainer) {
                cartContainer.removeEventListener('touchstart', () => {});
                cartContainer.removeEventListener('touchmove', () => {});
                cartContainer.removeEventListener('wheel', () => {});
            }

            const paymentSection = this.$el.querySelector('.overflow-y-auto');
            if (paymentSection) {
                paymentSection.removeEventListener('touchstart', () => {});
                paymentSection.removeEventListener('touchmove', () => {});
            }
        },

        generateQRCode(container) {
            if (!container || !this.paymentUrl) {
                console.log('Missing container or paymentUrl', { container, url: this.paymentUrl });
                return;
            }
            
            // Clear existing content
            container.innerHTML = '';
            
            // Create canvas element for QR code
            const canvas = document.createElement('canvas');
            canvas.className = 'mx-auto';
            
            // Generate QR code
            QRCode.toCanvas(canvas, this.paymentUrl, {
                width: 256,
                margin: 1,
                color: {
                    dark: document.documentElement.classList.contains('dark') ? '#FFFFFF' : '#000000',
                    light: '#00000000'
                }
            }).then(() => {
                if (container) {  // Double check container still exists
                    container.appendChild(canvas);
                    
                    // Create clickable link
                    const urlLink = document.createElement('a');
                    urlLink.href = this.paymentUrl;
                    urlLink.target = '_blank';
                    urlLink.rel = 'noopener noreferrer';
                    urlLink.className = 'text-sm text-blue-600 dark:text-blue-400 hover:underline break-all text-center block mt-2';
                    urlLink.textContent = this.paymentUrl;
                    container.appendChild(urlLink);
                }
            }).catch(error => {
                console.error('Error generating QR code:', error);
                if (container) {  // Check container exists before updating
                    container.innerHTML = '<div class="text-red-500">Failed to generate QR code</div>';
                }
            });
        },

        resetPaymentState() {
            this.isOpen = false;
            this.showPayment = false;
            this.selectedPayment = null;
            this.paymentConfirmed = false;
            this.purchasedItems = [];
            // Clear cart
            Alpine.store('cart').clearCart();
        }
    };
} 