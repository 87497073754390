import template from './itemComponent.html?raw'

// Export featureSlider so it's available globally for Alpine
window.featureSlider = function(features) {
    return {
        currentSlide: 0,
        featureChunks: [],
        totalSlides: 0,
        
        init() {
            // Split features into chunks of 3
            this.featureChunks = features.reduce((chunks, item, index) => {
                const chunkIndex = Math.floor(index / 3);
                if (!chunks[chunkIndex]) {
                    chunks[chunkIndex] = [];
                }
                chunks[chunkIndex].push(item);
                return chunks;
            }, []);
            
            this.totalSlides = this.featureChunks.length;
        },

        nextSlide() {
            if (this.currentSlide < this.totalSlides - 1) {
                this.currentSlide++;
            }
        },

        previousSlide() {
            if (this.currentSlide > 0) {
                this.currentSlide--;
            }
        },

        goToSlide(index) {
            this.currentSlide = index;
        }
    };
};

export function initItemComponent(itemData = null) {
    return {
        template,
        
        item: itemData || {
            name: 'Default Item Name',
            description: 'Default Description',
            price: 10,
            stock: 10,
            images: [
                'https://via.placeholder.com/600x400',
                'https://via.placeholder.com/600x400',
                'https://via.placeholder.com/600x400'
            ],
            features: [
                'Feature 1',
                'Feature 2',
                'Feature 3',
                'Feature 4',
                'Feature 5',
                'Feature 6'
            ]
        },
        
        isInCart() {
            return Alpine.store('cart').items.some(i => i.id === this.item.id);
        },
        
        isButtonDisabled() {
            return this.item.stock <= 0 || this.isInCart();
        },
        addToCart(quantity) {
            console.log('addToCart');
            if (this.isInCart() || this.item.stock <= 0) return;
            Alpine.store('cart').addItem(this.item, quantity);
        }
    }
}
