import template from './itemCarousel.html?raw'
import { initItemComponent } from './itemComponent.js'

export function initItemCarousel(items) {
    console.log('initItemCarousel called with items:', items);

    window.initItemComponent = initItemComponent
    
    return {
        currentItem: 0,
        touchStartY: 0,
        items: items || [],
        direction: 'up',
        isTransitioning: false,
        template,
        showSwipeHint: true,

        init() {
            console.log('itemCarousel initialized with items:', this.items);
            
            // Add passive touch listeners
            this.$el.addEventListener('touchstart', this.handleTouchStart.bind(this), { passive: true });
            this.$el.addEventListener('touchend', this.handleTouchEnd.bind(this), { passive: true });
            
            Alpine.effect(() => {
                const storeItems = Alpine.store('socket').items;
                if (storeItems && storeItems.length > 0) {
                    this.items = storeItems;
                }
            });

        },

        destroy() {
            this.$el.removeEventListener('touchstart', this.handleTouchStart.bind(this));
            this.$el.removeEventListener('touchend', this.handleTouchEnd.bind(this));
        },
        
        handleTouchStart(e) {
            if (this.isTransitioning) return;
            this.touchStartY = e.touches[0].clientY;
        },
        
        handleTouchEnd(e) {
            if (this.isTransitioning) return;
            const touchEndY = e.changedTouches[0].clientY;
            const diffY = this.touchStartY - touchEndY;
            
            if (Math.abs(diffY) > 50) {
                this.showSwipeHint = false;
                this.isTransitioning = true;
                if (diffY > 50) {
                    this.direction = 'up';
                    this.currentItem = (this.currentItem + 1) % this.items.length;
                } else if (diffY < -50) {
                    this.direction = 'down';
                    this.currentItem = (this.currentItem - 1 + this.items.length) % this.items.length;
                }
                setTimeout(() => {
                    this.isTransitioning = false;
                }, 500);
            }
        },

        goToItem(index) {
            if (this.isTransitioning || this.currentItem === index) return;
            this.isTransitioning = true;
            this.direction = index > this.currentItem ? 'up' : 'down';
            this.currentItem = index;
            setTimeout(() => {
                this.isTransitioning = false;
            }, 500);
        }
    }
}
