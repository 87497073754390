export function initQuantityControl(itemId, options = {}) {
    const defaults = {
        size: 'md',           // sm, md, lg
        showLabels: false,
        variant: 'default'    // default, compact
    };
    
    const config = { ...defaults, ...options };
    
    return {
        itemId,
        config,
        quantity: 1,

        getButtonClasses() {
            const sizes = {
                sm: 'w-8 h-8',
                md: 'w-10 h-10',
                lg: 'w-12 h-12'
            };
            
            return `${sizes[this.config.size]} flex items-center justify-center rounded-lg bg-white dark:bg-gray-700 border border-gray-200 dark:border-gray-600 text-gray-600 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-primary-500`;
        },

        getQuantityDisplay() {
            const item = Alpine.store('socket').items.find(i => i.id === this.itemId);
            if (!item || item.stock <= 0) return 'Out of Stock';
            return this.getCurrentQuantity();
        },

        isInCart() {
            return Alpine.store('cart').items.some(i => i.id === this.itemId);
        },

        getCurrentQuantity() {
            return this.isInCart() 
                ? Alpine.store('cart').items.find(i => i.id === this.itemId)?.quantity 
                : this.quantity;
        },

        isDecrementVisible() {
            return this.isInCart() || this.getCurrentQuantity() > 1;
        },

        isIncrementDisabled() {
            const item = Alpine.store('socket').items.find(i => i.id === this.itemId);
            return this.getCurrentQuantity() >= (item?.stock || 0);
        },

        handleDecrement() {
            if (this.isInCart()) {
                Alpine.store('cart').updateQuantity(this.itemId, this.getCurrentQuantity() - 1);
                this.quantity = this.getCurrentQuantity() == 0? 1 : this.getCurrentQuantity();
            } else if (this.quantity > 1) {
                this.quantity--;
            }
        },
        handleIncrement() {
            if (this.isInCart()) {
                Alpine.store('cart').updateQuantity(this.itemId, this.getCurrentQuantity() + 1);
            } else {
                const item = Alpine.store('socket').items.find(i => i.id === this.itemId);
                if (this.quantity < (item?.stock || 0)) {
                    this.quantity++;
                }
            }
        },

        getButtonText() {
            if (!this.itemId) return 'Add to Cart';
            const item = Alpine.store('socket').items.find(i => i.id === this.itemId);
            if (!item || item.stock <= 0) return 'Out of Stock';
            return this.isInCart() ? 'In Cart' : 'Add to Cart';
        }
    };
} 