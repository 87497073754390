import template from './headerComponent.html?raw'
import logoUrl from '../assets/logo.svg'

export function initHeaderComponent() {
    return {
        template,
        logoUrl,
        taps: 0,
        lastTapTime: 0,
        
        handleTouch() {
            const currentTime = new Date().getTime();
            const tapInterval = currentTime - this.lastTapTime;
            
            if (tapInterval > 500) {
                this.taps = 0;
            }
            
            this.taps++;
            this.lastTapTime = currentTime;

            if (this.taps === 3) {
                this.$dispatch('reset-welcome');
                this.$dispatch('clear-cart');
                this.taps = 0;
            }
        }
    }
} 