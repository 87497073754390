import '../styles/style.css'
import Alpine from 'alpinejs'
import persist from '@alpinejs/persist'
import { io } from 'socket.io-client'
import { initItemCarousel } from '../components/itemCarousel.js'
import { initCartComponent } from '../components/cartComponent.js'
import { initQuantityControl } from '../components/quantityControl.js'
import { initWelcomeOverlay } from '../components/welcomeOverlay.js'
import { initHeaderComponent } from '../components/headerComponent.js'


// Initialize Alpine plugins first
Alpine.plugin(persist)

// Make Alpine global
window.Alpine = Alpine

// Initialize stores
Alpine.store('socket', {
    lastPong: Alpine.$persist(null).as('lastPong'),
    history: Alpine.$persist([]).as('pong-history'),
    isConnected: false,
    items: [],
    images: [],

    init() {
        const backendUrl = import.meta.env.VITE_BACKEND_URL || 'http://localhost:3000';
        this.socket = io(backendUrl);
        
        this.socket.on('connect', () => {
            this.isConnected = true;
            this.getItems();
        });
        
        this.socket.on('disconnect', () => {
            this.isConnected = false;
        });
        
        this.socket.on('itemsResponse', (data) => {
            if (data.error) {
                console.error('Error fetching items:', data.error);
                return;
            }
            this.items = data.items;
            this.images = data.items.flatMap(item => item.images);
            console.log('itemsResponse', this.items);
            console.log('Flattened images:', this.images.length);
        });
    },

    getItems() {
        if (this.socket && this.isConnected) {
            this.socket.emit('getItems');
        }
    }
})

Alpine.store('theme', {
    isDark: Alpine.$persist(false).as('isDark'),
    toggle() {
        this.isDark = !this.isDark;
        this.updateTheme();
    },
    updateTheme() {
        if (this.isDark) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    },
    init() {
        this.updateTheme();
    }
});

// Initialize components
Alpine.data('taxiShop', () => ({
    init() {
        // Store is automatically initialized
    }
}))

// Make component initializers global
window.initItemCarousel = initItemCarousel
window.initCartComponent = initCartComponent
window.initQuantityControl = initQuantityControl
window.initWelcomeOverlay = initWelcomeOverlay
window.initHeaderComponent = initHeaderComponent

// Add to your existing Alpine.js registration
//Alpine.data('initWelcomeOverlay', initWelcomeOverlay);

// Start Alpine last
Alpine.start()
